import React from 'react'

function ProjectsIcon({ wrapperClassName }) {
  return (
    <svg version='1.0' xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512.000000 512.000000'
      preserveAspectRatio='xMidYMid meet'
      className={`${wrapperClassName}`}>
      <g transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
        stroke='none'>
        <path d='M1941 4782 c-65 -33 -75 -58 -81 -200 l-5 -127 -66 -17 c-36 -9 -103
          -31 -149 -47 -46 -17 -87 -31 -91 -31 -4 0 -37 40 -73 90 -36 49 -76 94 -87
          101 -45 24 -81 28 -119 15 -52 -18 -359 -244 -382 -281 -10 -18 -18 -49 -18
          -76 0 -42 8 -57 70 -144 42 -60 66 -103 61 -109 -4 -6 -28 -36 -52 -66 -24
          -30 -64 -86 -88 -123 -24 -37 -44 -67 -45 -67 -1 0 -49 16 -107 36 -111 38
          -158 40 -204 10 -39 -26 -50 -50 -120 -264 -61 -189 -65 -206 -54 -244 17 -66
          53 -93 173 -130 l106 -33 0 -167 0 -166 -73 -22 c-162 -49 -209 -86 -210 -168
          0 -20 28 -124 62 -230 91 -281 114 -300 301 -247 52 15 103 31 112 36 13 7 24
          -3 54 -52 20 -34 63 -94 96 -135 l58 -74 -67 -92 c-61 -83 -67 -97 -71 -148
          -4 -49 -1 -60 23 -88 34 -40 334 -259 375 -274 41 -14 93 -3 129 28 16 13 55
          60 87 105 40 55 63 79 74 75 8 -2 47 -17 85 -31 39 -15 102 -35 140 -45 l70
          -18 5 -128 c8 -210 14 -214 332 -214 205 0 226 2 256 20 17 11 32 23 32 28 0
          4 -27 16 -61 26 -82 24 -242 105 -320 162 -251 183 -409 407 -493 698 -14 48
          -26 98 -26 111 0 15 -23 42 -69 84 -430 382 -455 1057 -54 1468 261 266 645
          370 1003 272 162 -44 357 -162 466 -281 49 -54 53 -56 135 -72 264 -53 502
          -184 678 -372 l71 -76 84 27 c60 19 93 36 116 60 54 55 52 83 -24 316 -36 112
          -72 211 -79 220 -21 27 -80 59 -110 59 -15 0 -76 -16 -134 -35 -59 -19 -108
          -33 -109 -32 0 1 -18 29 -39 62 -20 33 -62 90 -91 127 -30 37 -54 70 -54 74 0
          4 27 44 60 90 86 118 96 157 62 222 -24 44 -362 292 -400 293 -89 4 -111 -10
          -192 -122 -36 -49 -69 -89 -74 -89 -4 0 -49 15 -100 34 -50 19 -117 40 -148
          47 l-58 12 0 111 c0 96 -3 118 -22 156 -39 75 -58 80 -308 80 -185 0 -220 -2
          -249 -18z'/>
        <path d='M2075 3763 c-106 -15 -266 -78 -360 -143 -184 -126 -326 -338 -372
          -554 -21 -101 -13 -297 17 -391 30 -93 87 -206 146 -285 l48 -65 13 105 c27
          213 97 402 213 576 72 108 233 266 348 341 156 103 368 183 526 198 94 10 95
          12 35 55 -182 132 -407 192 -614 163z'/>
        <path d='M2720 3393 c-343 -37 -622 -202 -807 -478 -127 -190 -186 -391 -186
          -635 0 -300 90 -531 291 -741 106 -111 203 -182 337 -245 254 -121 566 -137
          831 -43 130 46 201 85 350 194 10 7 34 -10 93 -69 l79 -79 -24 -45 c-28 -52
          -30 -88 -9 -140 21 -50 714 -745 771 -772 86 -42 143 -20 256 98 88 93 106
          134 88 205 -10 37 -57 88 -378 410 -202 203 -380 375 -396 384 -42 22 -123 19
          -157 -6 -16 -12 -32 -21 -36 -21 -5 0 -44 35 -87 79 l-78 78 51 64 c71 88 155
          261 186 382 126 500 -122 1039 -585 1274 -167 85 -399 127 -590 106z m360
          -362 c314 -106 503 -363 527 -716 14 -219 -102 -478 -280 -625 -74 -60 -186
          -120 -282 -150 -107 -34 -283 -39 -397 -11 -272 65 -476 252 -570 522 -31 89
          -32 101 -32 234 1 122 4 151 27 228 68 224 197 381 391 476 129 64 215 81 381
          77 126 -2 149 -6 235 -35z'/>
        <path d='M2715 2906 c-38 -8 -95 -23 -126 -35 -364 -138 -501 -603 -277 -937
          118 -176 319 -272 548 -261 464 22 738 535 500 937 -131 222 -397 344 -645
          296z'/>
      </g>
    </svg>
  )
}

export default ProjectsIcon;