import React from 'react'

function SequelizeIcon({ wrapperClassName=''}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" className={wrapperClassName}>
      <g id="original">
        <path fill="#454A4C"  d="M101.84,41.72V86L64.18,108.32l-.34.31V125.2l.34.32,53-30.64V33.12l-.5-.12-15,8.36.08.36"/>
        <path fill="#758085" d="M26.4,86.4l37.78,21.92v17.2L10.78,94.76V33.24l.55-.08,14.91,8.67.16.5V86.4"/>
        <path fill="#A3B3BC" d="M26.4,42.32,10.78,33.24,64.06,2.48l53.16,30.64-15.38,8.6L64.06,20.16,26.4,42.32"/>
        <path fill="#454A4C"  d="M63.53,81.33l-.41-.42V64.27l.41-.21.1-.41L77.9,55.33l.44.1V72.58l-14.8,8.76"/>
        <path fill="#758085" d="M48.48,73.11V55.3l.41,0,14.51,8.45.12.33V81.33L48.48,73.11"/>
        <path fill="#A3B3BC" d="M63.29,46.54,48.48,55.3l15.05,8.76,14.8-8.64L63.29,46.54"/>
        <path fill="#454A4C"  d="M45.11,92.19l-.41-.42V75.13l.41-.21.1-.41,14.27-8.32.44.1V83.43l-14.8,8.76"/>
        <path fill="#758085" d="M30.06,84V66.16l.41,0L45,74.59l.12.33V92.19L30.06,84"/>
        <path fill="#A3B3BC" d="M44.86,57.4l-14.8,8.76,15.05,8.76,14.8-8.64L44.86,57.4"/>
        <path fill="#454A4C"  d="M83.27,92.6l-.41-.42V75.54l.41-.21.1-.41L97.64,66.6l.44.1V83.84L83.27,92.6"/>
        <path fill="#758085" d="M68.22,84.38V66.57l.41,0L83.15,75l.12.33V92.6L68.22,84.38"/>
        <path fill="#A3B3BC" d="M83,57.81l-14.8,8.76,15.05,8.76,14.8-8.64L83,57.81"/>
        <path fill="#454A4C"  d="M64.85,103.46l-.41-.42V86.4l.41-.21.1-.41,14.27-8.32.44.1V94.7l-14.8,8.76"/>
        <path fill="#758085" d="M49.8,95.23V77.43l.41,0,14.51,8.45.12.33v17.27L49.8,95.23"/>
        <path fill="#A3B3BC" d="M64.6,68.67,49.8,77.43l15.05,8.76,14.8-8.64L64.6,68.67"/>
        <path fill="#454A4C"  d="M63.53,57.73l-.41-.42V40.67l.41-.21.1-.41L77.9,31.72l.44.1V49l-14.8,8.76"/>
        <path fill="#758085" d="M48.48,49.5V31.7l.41,0,14.51,8.45.12.33V57.73L48.48,49.5"/>
        <path fill="#A3B3BC" d="M63.29,22.94,48.48,31.7l15.05,8.76,14.8-8.64L63.29,22.94"/>
        <path fill="#454A4C"  d="M45.11,68.59l-.41-.42V51.53l.41-.21.1-.41,14.27-8.32.44.1V59.83l-14.8,8.76"/>
        <path fill="#758085" d="M30.06,60.36V42.55l.41,0L45,51l.12.33V68.59L30.06,60.36"/>
        <path fill="#A3B3BC" d="M44.86,33.8l-14.8,8.76,15.05,8.76,14.8-8.64L44.86,33.8"/>
        <path fill="#454A4C"  d="M83.27,69l-.41-.42V51.94l.41-.21.1-.41L97.64,43l.44.1V60.24L83.27,69"/>
        <path fill="#758085" d="M68.22,60.77V43l.41,0,14.51,8.45.12.33V69L68.22,60.77"/>
        <path fill="#A3B3BC" d="M83,34.21,68.22,43l15.05,8.76,14.8-8.64L83,34.21"/>
        <path fill="#454A4C"  d="M64.85,79.85l-.41-.42V62.79l.41-.21.1-.41,14.27-8.32.44.1V71.09l-14.8,8.76"/>
        <path fill="#758085" d="M49.8,71.63V53.82l.41,0,14.51,8.45.12.33V79.85L49.8,71.63"/>
        <path fill="#A3B3BC" d="M64.6,45.06,49.8,53.82l15.05,8.76,14.8-8.64L64.6,45.06"/>
      </g>
    </svg>
  )
}

export default SequelizeIcon